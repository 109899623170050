// import { FaSun, FaMoon} from ";

// const Toggle = () => {
//     const { theme, setTheme } = React.useContext(ThemeContext);

//     return (
//         <div className="transition duration-500 ease-in-out rounded-full p-2">
//             {theme === 'dark' ? (
//                 <FaSun
//                     onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
//                     className="text-gray-500 dark:text-gray-400 text-2xl cursor-pointer"
//                 />
//             ) : (
//                     <FaMoon
//                         onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
//                         className="text-gray-500 dark:text-gray-400 text-2xl cursor-pointer"
//                     />
//                 )}
//         </div>


//     );
// };

// export default Toggle;
/* This example requires Tailwind CSS v2.0+ */

import React from 'react';
import { Switch } from '@headlessui/react'
import { ThemeContext } from './ThemeContext';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Example() {
    const { theme, setTheme } = React.useContext(ThemeContext);

    return (
        <Switch
            checked={theme}
            onChange={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
            className={classNames(
                theme === 'dark' ? 'bg-black' : 'bg-white',
                'z-50 outline-none scroll-smooth relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200'
            )}
        >
            <span className="sr-only">Use setting</span>
            <span
                className={classNames(
                    theme === 'dark' ? 'translate-x-5 bg-black' : 'translate-x-0 bg-white',
                    'pointer-events-none relative inline-block h-5 w-5 rounded-full  shadow transform ring-0 transition ease-in-out duration-200'
                )}
            >
                <span
                    className={classNames(
                        theme === 'dark' ? 'opacity-0 ease-out  duration-100' : 'opacity-100 ease-in duration-200',
                        'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
                    )}
                    aria-hidden="true"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                </span>
                <span
                    className={classNames(
                        theme === 'dark' ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100',
                        'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
                    )}
                    aria-hidden="true"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
                    </svg>
                </span>
            </span>
        </Switch>
    )
}
