

import { useScroll } from "../components/useScroll";

import { motion } from "framer-motion";
import { scrollReveal } from "../animation";

export default function Example() {
    const [element, controls] = useScroll();

    return (
        <motion.div
            exit="exit"
            variants={scrollReveal}
            ref={element}
            animate={controls}
            initial="hidden" >
        <div className="relative px-4 py-16 sm:px-6 sm:py-20 lg:py-20 lg:px-8">
        <h1 className="text-center md:text-5xl text-5xl sm:text-5xl font-extrabold font-montserrat lg:text-7xl">
                <span className="block dark:text-transparent text-white bg-clip-text  dark:bg-gradient-to-r dark:p-[2px] dark:from-[#D7D7D7] dark:to-[#4d6bcb] ">About Me</span>
            </h1>
        </div>
        </motion.div>
    )
}
