import React from "react";
//Page Components
import Nav from "../components/nav";
import Banner from "../components/banner";
import Footer from "../components/footer";

import AboutMe from "../components/meText";
import About from "../components/about";

import Empty from "../components/empty";

import background1 from "../img/Picture1.png";
import { motion } from "framer-motion";


import ScrollTop from "../components/ScrollTop";
//Animations
// import { motion } from "framer-motion";

const Home = () => {

  return (


    <motion.div className=" bg-fixed min-h-full max-h-full max-w-screen" style={{
      backgroundImage: `url(${background1})`
    }}>
          <Nav />

          <Banner />

          <AboutMe />
          <About />
<Empty/>
          <Footer />
          <ScrollTop />
    </motion.div>


  );
};

export default Home;