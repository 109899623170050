import reactImage from "../img/react.jpg"

import { useScroll } from "../components/useScroll";

import { motion } from "framer-motion";
import { scrollReveal, lineAnim } from "../animation";


export default function Example() {
    const [element, controls] = useScroll();

    return (
        <motion.div
            exit="exit"
            variants={scrollReveal}
            ref={element}
            animate={controls}
            initial="hidden" >
            <div className="py-4 xl:py-4 px-4 sm:px-6 lg:px-8 bg-foreground dark:bg-background shadow-lg overflow-hidden">
                <div className="max-w-max lg:max-w-7xl mx-auto">
                    <div className="relative">
                        <div className="relative md:p-6">
                            <div className="lg:grid lg:grid-cols-2 lg:gap-6">
                                <div className="prose prose-indigo prose-lg text-xl font-quicksand font-medium text-background dark:text-accent1 lg:max-w-none">
                                    <p>
                                        Since beginning my journey as a freelance developer, I’ve done remote work for agencies, startups, local businesses and collaborated with talented people to create web products for both business and consumer use.
                                    </p>
                                    <p>
                                        I create successful responsive websites that are fast, easy to use, and built with best practices. The main area of my expertise is front-end development, HTML, CSS, JS, building small and medium web apps, features and coding interactive layouts.
                                    </p>
                                    <p>
                                        I also have full-stack developer experience with Laravel, an open-source web application PHP framework.
                                    </p>
                                    <p>Visit my <a href="https://www.linkedin.com/in/nigel-overman-216b9b196/">LinkedIn</a> profile for more details or <a href="/contact">contact</a> me.</p>
                                </div>
                                <div className="text-xl font-quicksand font-medium text-background dark:text-accent1 sm:py-4 md:py-4 lg:py-0 py-4 lg:max-w-none">

                                    <div className="mb-1 text-base font-medium text-background dark:text-foreground">Motivation</div>

                                    <div className="w-full bg-gray-700 rounded-full h-2.5 mb-4 ">
                                        <motion.div
                                            exit="exit"
                                            variants={lineAnim}
                                            ref={element}
                                            animate={controls}
                                            initial="hidden" className="dark:bg-foreground bg-foreground2 transition-all ease-out duration-1000 h-2.5 rounded-full w-0"></motion.div>
                                    </div>

                                    <div className="mb-1 text-base font-medium text-background dark:text-accent1 ">Skilled</div>

                                    <div className="w-full bg-gray-700 rounded-full h-2.5 mb-4 ">
                                        <motion.div
                                            exit="exit"
                                            variants={lineAnim}
                                            ref={element}
                                            animate={controls}
                                            initial="hidden" className="bg-accent1 transition-all ease-out duration-1000 h-2.5 rounded-full w-0"></motion.div>
                                    </div>

                                    <div className="mb-1 text-base font-medium text-background dark:text-accent2 ">Communication</div>

                                    <div className="w-full bg-gray-700 rounded-full h-2.5 mb-4  ">
                                        <motion.div
                                            exit="exit"
                                            variants={lineAnim}
                                            ref={element}
                                            animate={controls}
                                            initial="hidden" className="bg-accent2 transition-all ease-out duration-1000 h-2.5 rounded-full w-0"></motion.div>
                                    </div>

                                    <div className="mb-1 text-base font-medium text-background dark:text-accent3 ">Quality</div>

                                    <div className="w-full bg-gray-700 rounded-full h-2.5 mb-4 ">
                                        <motion.div
                                            exit="exit"
                                            variants={lineAnim}
                                            ref={element}
                                            animate={controls}
                                            initial="hidden" className="bg-accent3 transition-all ease-out duration-1000 h-2.5 rounded-full w-0"></motion.div>
                                    </div>
                                    <div className="mx-auto max-w-md py-4 sm:max-w-3xl  ">
                                        <div className="aspect-w-10 aspect-h-6 rounded-xl shadow-xl overflow-hidden sm:aspect-w-16 sm:aspect-h-7 lg:aspect-h-9 lg:h-full">
                                            <img
                                                className="object-cover object-top rounded-xl dark:opacity-80 lg:h-full lg:w-full"
                                                src={reactImage}
                                                alt=""
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}
