import { useScroll } from "../components/useScroll";

import { motion } from "framer-motion";
import { scrollReveal } from "../animation";

export default function Example() {
    const [element, controls] = useScroll();

    return (
        <motion.div
            exit="exit"
            variants={scrollReveal}
            ref={element}
            animate={controls}
            initial="hidden" >

            <div className="py-4 xl:py-8 px-4 sm:px-6 lg:px-8 bg-foreground dark:bg-background shadow-lg overflow-hidden">
                <div className="max-w-max lg:max-w-7xl mx-auto">
                    <div className="relative">
                        <div className="relative md:p-6">
                            <div className="lg:grid lg:grid-cols-1 lg:gap-6">
                                <div className="prose prose-indigo prose-lg text-xl font-quicksand font-medium text-background dark:text-accent1 lg:max-w-none">
                                    <p>
                                        I’m a Software Developer located in The Netherlands. I have a serious passion for learning, UI effects and creating intuitive, dynamic user experiences.
                                    </p>
                                    <p>
                                        Well-organised person, problem solver, independent employee with high attention to detail and great communication skills. Fan of football, Formula 1, going to the gym and a family person.
                                    </p>
                                    <p>
                                        Interested in the entire developer spectrum and working on ambitious projects with positive people.
                                    </p>
                                    <p>I have a degree in;</p>
                                    <ol>
                                        <li>Software Development.</li>
                                        <li>International Baccalaureate.</li>
                                    </ol>
                                    <p>
                                        What is <a href="https://www.ibo.org/about-the-ib/"> International Baccalaureate? </a>
                                    </p>
                                    <p>
                                        This education meant that I've officially completed 6 years of high school with <span className="font-extrabold text-background dark:text-accent2"> more than 80% </span> of my courses in <span className="font-extrabold text-accent2"> English </span> and added an expertise level of education on <span className="font-extrabold text-accent2">Business English</span> where I reached up to a <a href="https://www.ibo.org/contentassets/599acfec42b94ad9a2e0a351fd7f02d5/letter-to-universities-regarding-english-language-requirements-en.pdf">B2+</a> level.
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}
