import mainlogo from "../img/mainlogo.png";
import sidewatch from "../img/1.jpg";

import { useScroll } from "../components/useScroll";


import { motion } from "framer-motion";
import { scrollReveal, photoAnim } from "../animation";

export default function Example() {
    const [element, controls] = useScroll();

    return (
        <motion.div
            exit="exit"
            variants={scrollReveal}
            ref={element}
            animate={controls}
            initial="hidden" >
                <motion.div className="">
                    <motion.div className="relative">
                        <motion.div className=" pt-12 lg:py-20 mx-auto sm:px-6 lg:px-8">
                            <motion.div className="relative sm:rounded-2xl sm:overflow-hidden">
                                <motion.div className="w-3/4 mx-auto">
                                    <motion.img
                                        className=""
                                        src={mainlogo}
                                        alt="Logo"
                                    />
                                </motion.div>

                            </motion.div>
                        </motion.div>
                    </motion.div>

                    <motion.div className="lg:py-16 shadow-lg  dark:bg-background bg-foreground">
                        <motion.div className="pb-16 lg:pb-0 lg:z-10 lg:relative">
                            <motion.div className="lg:mx-auto lg:max-w-7xl lg:-px-24 lg:grid lg:grid-cols-2 lg:gap-12">

                                <motion.div className="mt-12 lg:m-0 lg:col-span-1">
                                    <motion.div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 lg:py-20 lg:max-w-none">
                                        <motion.blockquote>
                                            <motion.div>
                                                <motion.svg
                                                    className="h-12 w-12 text-background dark:text-foreground opacity-50"
                                                    fill="currentColor"
                                                    viewBox="0 0 32 32"
                                                    aria-hidden="true"
                                                >
                                                    <motion.path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                                                </motion.svg>
                                                <motion.h1 className="mt-6 text-4xl font-montserrat font-medium text-background dark:text-foreground">
                                                    Hi, <br />
                                                    I'm Nigel, <br />
                                                </motion.h1>
                                                <motion.p className="mt-6 text-2xl font-quicksand font-medium text-background dark:text-accent1">
                                                I'm a software developer and I'm here to help you make all your web and app ideas into reality.
                                                </motion.p>
                                            </motion.div>
                                            <motion.footer className="mt-6 font-quicksand">
                                                <motion.p className="text-base font-medium text-background dark:text-accent1">Nigel Overman</motion.p>
                                                <motion.p className="text-base font-medium text-background dark:text-accent1">CEO at Dynamic Software Development</motion.p>
                                            </motion.footer>
                                        </motion.blockquote>
                                    </motion.div>
                                </motion.div>
                                <motion.div className="relative lg:col-span-1  lg:-my-8">
                                    <motion.div aria-hidden="true" className="absolute inset-x-0 top-0 h-1/2 lg:hidden" />
                                    <motion.div className="mx-auto max-w-md py-4 px-4 sm:max-w-3xl sm:px-6 lg:p-0 lg:h-full">
                                        <motion.div className="aspect-w-10 aspect-h-6 rounded-xl shadow-xl overflow-hidden sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full">
                                            <motion.img
                                                                               exit="exit"
                                                                               variants={photoAnim}
                                                                               ref={element}
                                                                               animate={controls}
                                                                               initial="hidden" 
                                                className="object-cover lg:h-full lg:w-full"
                                                src={sidewatch}
                                                alt=""
                                            />
                                        </motion.div>
                                    </motion.div>
                                </motion.div>
                            </motion.div>
                        </motion.div>
                    </motion.div>
                </motion.div>
                
                
            </motion.div>
    )
}