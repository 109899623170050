import React from "react";
//Page Components
import Nav from "../components/nav";
import Footer from "../components/footer";

import PortfolioHeader from "../components/portfolioText";
import PortfolioContent from "../components/portfolioLarge";

import Empty from "../components/empty";

import ScrollTop from "../components/ScrollTop";

import background1 from "../img/Picture1.png";

//Animations
import { motion } from "framer-motion";

const Home = () => {
  return (
    <motion.div className=" bg-fixed min-h-full max-h-full max-w-screen" style={{
      backgroundImage: `url(${background1})`
    }}>
      <Nav />

      <PortfolioHeader />
      <PortfolioContent />

      <Empty />
      <Footer />
      <ScrollTop />

    </motion.div>
  );
};

export default Home;