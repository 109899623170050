const navigation = [
    { name: 'Home', href: '/' },
    { name: 'About', href: '/about' },
    { name: 'Skill', href: '/skill' },
    { name: 'Work', href: '/work' },
    { name: 'Contact', href: '/contact' },

]

export default function Example() {
    return (
        <div>
            <div className="relative px-4 sm:px-6 lg:px-8">
                <h1 className="text-center text-7xl font-extrabold font-montserrat sm:text-5xl lg:text-7xl">
                    <span className="block text-transparent bg-clip-text bg-gradient-to-r p-[2px] from-[#D7D7D7] to-[#4d6bcb] "></span>
                </h1>
            </div>


            <footer className="bg-foreground2 dark:bg-background4">
            <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
                    <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
                        {navigation.map((item) => (

                            <div className="px-5 py-2">
                                <a href={item.href} className="text-base text-background dark:text-gray-500 hover:text-gray-900"> {item.name} </a>
                            </div>
                        ))}
                    </nav>
                    <div>

                    </div>
                    <p className="mt-8 text-center text-base text-background dark:text-gray-400">&copy; 2022 Dynamic Software Development, All rights
                        reserved.</p>
                </div>
            </footer>
        </div>
    )
}
