import { ArrowSmUpIcon } from '@heroicons/react/solid'

import React, {useRef} from 'react';

const ScrollButton = () =>{
  
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
    
  return (
    <button onClick={scrollToTop} className="h-10 w-10 z-50 outline-none scroll-smooth fixed rounded-xl bg-foreground bottom-5 right-5">
     <ArrowSmUpIcon aria-hidden="true"  />
    </button>
  );
}
  
export default ScrollButton;