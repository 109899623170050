import Glow from "../img/Glow.png";
import MJ from "../img/MajorRond.png";
import Ovetra from "../img/Ovetra.jpg";
import Roverman from "../img/Roverman.png";
import Nigel from "../img/nigel.png";
import PMK from "../img/PMK.jpg";
import AM from "../img/AM.jpg";

import { useScroll } from "../components/useScroll";

import { motion } from "framer-motion";
import { scrollReveal } from "../animation";

export default function Example() {
    const [element, controls] = useScroll();

    return (
        <motion.div
            exit="exit"
            variants={scrollReveal}
            ref={element}
            animate={controls}
            initial="hidden" >
                <ul className="grid grid-cols-2 shadow-lg sm:grid-cols-5 lg:grid-cols-5 bg-foreground dark:bg-background font-quicksand py-5 gap-x-4 gap-y-1  sm:gap-x-5 xl:gap-x-1">
                    <li className="relative">
                    <div className="group block w-full h-full opacity-75 dark:opacity-50 hover:opacity-100 dark:hover:opacity-100 aspect-w-10 aspect-h-10 rounded-lg   overflow-hidden">
                            <img alt="glow" className="pointer-events-none scale-75 group-hover:scale-90 bg-black ease-in duration-200 group-hover:opacity-100"
                                src={Glow} />
                            <div
                                className="absolute text-background top-1/2 left-1/2  -translate-x-1/2 w-full h-0 flex flex-col justify-center items-center  opacity-0  group-hover:opacity-100 duration-300">
                                <a className="mt-5 px-10 text-m py-10 rounded-full text-center bg-foreground duration-300" href="/work/glow">View <br />Project</a>
                            </div>
                        </div>
                    </li>
                    <li className="relative">
                    <div className="group block w-full h-full opacity-75 dark:opacity-50 hover:opacity-100 dark:hover:opacity-100 aspect-w-10 aspect-h-10 rounded-lg   overflow-hidden">
                            <img alt="mj" className="pointer-events-none scale-75 group-hover:scale-90 bg-pear ease-in duration-200 group-hover:opacity-100"
                                src={MJ} />
                            <div
                                className="absolute text-background top-1/2 left-1/2 -translate-x-1/2 w-full h-0 flex flex-col justify-center items-center  opacity-0  group-hover:opacity-100 duration-300">
                                <a className="mt-5 px-10 text-m py-10 rounded-full text-center bg-foreground duration-300" href="/work/major-capital">View <br />Project</a>
                            </div>
                        </div>
                    </li>
                    <li className="relative">
                    <div className="group block w-full h-full opacity-75 dark:opacity-50 hover:opacity-100 dark:hover:opacity-100 aspect-w-10 aspect-h-10 rounded-lg   overflow-hidden">
                            <img alt="ovetra" className="pointer-events-none scale-75 group-hover:scale-90 ease-in duration-200 group-hover:opacity-100"
                                src={Ovetra} />
                            <div
                                className="absolute text-background top-1/2 left-1/2 -translate-x-1/2 w-full h-0 flex flex-col justify-center items-center  opacity-0  group-hover:opacity-100 duration-300">
                                <a className="mt-5 px-10 text-m py-10 rounded-full text-center bg-foreground duration-300" href="/work/ovetra">View <br />Project</a>
                            </div>
                        </div>
                    </li>
                    <li className="relative">
                    <div className="group block w-full h-full opacity-75 dark:opacity-50 hover:opacity-100 dark:hover:opacity-100 aspect-w-10 aspect-h-10 rounded-lg   overflow-hidden">
                            <img alt="roverman" className="pointer-events-none scale-75 group-hover:scale-90 ease-in duration-200 group-hover:opacity-100"
                                src={Roverman} />
                            <div
                                className="absolute text-background top-1/2 left-1/2 -translate-x-1/2 w-full h-0 flex flex-col justify-center items-center  opacity-0  group-hover:opacity-100 duration-300">
                                <a className="mt-5 px-10 text-m py-10 rounded-full text-center bg-foreground duration-300" href="/work/rudie-crossman">View <br />Project</a>
                            </div>
                        </div>
                    </li>
                    <li className="relative">
                <div className="group block w-full h-full opacity-75 dark:opacity-50 hover:opacity-100 dark:hover:opacity-100 aspect-w-10 aspect-h-10 rounded-lg   overflow-hidden">
                    <img alt="roverman" className="pointer-events-none scale-75 group-hover:scale-90 ease-in duration-200 group-hover:opacity-100"
                        src={AM} />
                    <div
                        className="absolute text-background top-1/2 left-1/2 -translate-x-1/2 w-full h-0 flex flex-col justify-center items-center  opacity-0  group-hover:opacity-100 duration-300">
                        <a className="mt-5 px-10 text-m py-10 rounded-full text-center bg-foreground duration-300" href="/work/Amin-Markai">View <br />Project</a>
                    </div>
                </div>
            </li>
                    <li className="relative">
                    <div className="group block w-full h-full opacity-75 dark:opacity-50 hover:opacity-100 dark:hover:opacity-100 aspect-w-10 aspect-h-10 rounded-lg   overflow-hidden">
                            <img alt="nigel" className="pointer-events-none scale-75 group-hover:scale-90 ease-in duration-200 group-hover:opacity-100"
                                src={Nigel} />
                            <div
                                className="absolute text-background top-1/2 left-1/2 -translate-x-1/2 w-full h-0 flex flex-col justify-center items-center  opacity-0  group-hover:opacity-100 duration-300">
                                <a className="mt-5 px-10 text-m py-10 rounded-full text-center bg-foreground duration-300" href="/work/nigel-overman">View <br />Project</a>
                            </div>
                        </div>
                    </li>
                    <li className="relative">
                    <div className="group block w-full h-full opacity-75 dark:opacity-50 hover:opacity-100 dark:hover:opacity-100 aspect-w-10 aspect-h-10 rounded-lg   overflow-hidden">
                            <img alt="PMK" className="pointer-events-none scale-75 group-hover:scale-90 ease-in duration-200 group-hover:opacity-100"
                                src={PMK} />
                            <div
                                className="absolute text-background top-1/2 left-1/2 -translate-x-1/2 w-full h-0 flex flex-col justify-center items-center  opacity-0  group-hover:opacity-100 duration-300">
                                <a className="mt-5 px-10 text-m py-10 rounded-full text-center bg-foreground duration-300" href="/work/pmk-interiors">View <br />Project</a>
                            </div>
                        </div>
                    </li>
                </ul>
        </motion.div>
    )
}
