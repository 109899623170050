import React from "react";
//Page Components
import Nav from "../../components/nav";
import Footer from "../../components/footer";

import background1 from "../../img/Picture1.png";

import ScrollTop from "../../components/ScrollTop";
//Animations
import { motion } from "framer-motion";

import ModalImage from "react-modal-image-react-17";


//gallery
import image1 from "../../img/GLOW/1.png"
import image2 from "../../img/GLOW/2.png"
import image3 from "../../img/GLOW/3.png"
import image4 from "../../img/GLOW/4.png"
import image5 from "../../img/GLOW/5.png"
import image6 from "../../img/GLOW/6.png"
import image7 from "../../img/GLOW/7.png"
import image8 from "../../img/GLOW/8.png"
import image9 from "../../img/GLOW/9.png"
import image10 from "../../img/GLOW/10.png"
import image11 from "../../img/GLOW/11.png"
import image12 from "../../img/GLOW/12.png"
import image13 from "../../img/GLOW/13.png"
import image14 from "../../img/GLOW/14.png"
import image15 from "../../img/GLOW/15.png"
import image16 from "../../img/GLOW/16.png"



const images = [
    { source: image1, isOpen: false },
    { source: image2, isOpen: false },
    { source: image3, isOpen: false },
    { source: image4, isOpen: false },
    { source: image5, isOpen: false },
    { source: image6, isOpen: false },
    { source: image7, isOpen: false },
    { source: image8, isOpen: false },
    { source: image9, isOpen: false },
    { source: image10, isOpen: false },
    { source: image11, isOpen: false },
    { source: image12, isOpen: false },
    { source: image13, isOpen: false },
    { source: image14, isOpen: false },
    { source: image15, isOpen: false },
    { source: image16, isOpen: false },
];



const Home = () => {
    return (
        <motion.div className=" bg-fixed min-h-full max-h-full max-w-screen"
            style={{ backgroundImage: `url(${background1})` }}

            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <Nav />

            <div className="relative px-4 py-16 sm:px-6 sm:py-20 lg:py-20 lg:px-8">
                <h1 className="text-center md:text-5xl text-5xl sm:text-5xl font-extrabold font-montserrat lg:text-7xl">
                    <a href="https://www.glow-foryou.nl/en" className="block text-transparent bg-clip-text bg-gradient-to-r p-[2px] from-[#D7D7D7] to-[#4d6bcb] ">GLOW-FORYOU</a>
                </h1>
            </div>

            <section class="overflow-hidden min-h-screen text-gray-700 ">
                <div class="container px-5 py-2 mx-auto lg:pt-12 lg:px-32">
                    <div class="flex flex-wrap -m-1 md:-m-2">
                        {images.map((item) => (
                            <div class="flex flex-wrap w-1/2">
                                <div class="w-full p-1 md:p-2">

                                    {/* <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                                        src={item.source} /> */}
                                    <ModalImage
                                        className="block object-cover object-center w-full h-full rounded-lg"
                                        small={item.source}
                                        large={item.source}
                                        hideDownload
                                    />;

                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </section>


            <Footer />
            <ScrollTop />


        </motion.div>
    );
};

export default Home;