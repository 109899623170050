import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { NavLink } from 'react-router-dom'

import Toggle from "../components/ThemeToggle"

import logo from "../img/logo.png"

const navigation = [
    { name: 'Home', href: '/' },
    { name: 'About', href: '/about' },
    { name: 'Skill', href: '/skill' },
    { name: 'Work', href: '/work' },
    { name: 'Contact', href: '/contact' },

]

export default function Example() {
    return (
        <div className='sticky top-0 z-50'>
            <div className="bg-foreground2 dark:bg-background4 font-quicksand ">
                <div className="relative py-4 ">
                    <Popover>
                        <div className="max-w-7xl max-h-screen mx-auto px-4 sm:px-6">
                            <nav className="relative flex items-center justify-between sm:h-10 md:justify-center" aria-label="Global">
                                <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
                                    <div className="flex items-center justify-between w-full md:w-auto">
                                        <a href="/">
                                            <span className="sr-only">Dynamic</span>
                                            <img
                                                className="h-8 w-auto sm:h-10"
                                                src={logo}
                                                alt=""
                                            />
                                        </a>
                                        <Toggle />

                                        <div className="-mr-2 flex items-center md:hidden">
                                            <Popover.Button className=" rounded-md p-2 inline-flex items-center justify-center text-background dark:text-white hover:text-gray-500 focus:outline-none">
                                                <span className="sr-only">Open main menu</span>
                                                <MenuIcon className="h-6 w-6" aria-hidden="true" />
                                            </Popover.Button>
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                                <div className="hidden md:flex md:space-x-10">
                                    {navigation.map((item) => (
                                        <NavLink key={item.name} to={item.href} className={({ isActive }) =>
                                            isActive ? "font-medium text-accent3 hover:text-accent2" : "font-medium text-background dark:text-white hover:text-foreground"
                                        }>
                                            {item.name}
                                        </NavLink>
                                    ))}
                                    
                                </div>
                                    
                            </nav>
                            
                        </div>

                        <Transition
                            as={Fragment}
                            enter="duration-150 ease-out"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="duration-100 ease-in"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Popover.Panel
                                focus
                                className="absolute z-10 top-0 inset-x-0 p-2 bg-foreground dark:bg-background transition transform origin-top-right md:hidden"
                            >
                                <div className="rounded-lg shadow-md  ring-1 ring-black ring-opacity-5 overflow-hidden">
                                    <div className="px-5 pt-4 flex  items-center justify-between">
                                        <div>
                                            <img
                                                className="h-8 w-auto"
                                                src={logo}
                                                alt=""
                                            />
                                        </div>
                                        
                                        <div className="-mr-2">
                                            <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center text-background dark:text-white hover:text-gray-500  focus:outline-none">
                                                <span className="sr-only">Close main menu</span>
                                                <XIcon className="h-6 w-6" aria-hidden="true" />
                                            </Popover.Button>
                                        </div>
                                    </div>
                                    <div className="px-2 pt-2 pb-3  text-center space-y-1">
                                        {navigation.map((item) => (
                                            <a
                                                key={item.name}
                                                href={item.href}
                                                className="block px-3 py-2 rounded-md text-base font-medium text-background dark:text-indigo-200 z-50"
                                            >
                                                {item.name}
                                            </a>

                                        ))}
                                    </div>

                                </div>
                            </Popover.Panel>
                        </Transition>
                    </Popover>

                </div>
            </div>
        </div>
    )
}

