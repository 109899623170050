import React from "react";
//Page Components
import Nav from "../components/nav";
import Banner from "../components/banner";
import Footer from "../components/footer";

import AboutMe from "../components/meText";
import About from "../components/about";

import SkillsHeader from "../components/skillText";
import SkillsContent from "../components/skill";

import PortfolioHeader from "../components/portfolioText";
import PortfolioContent from "../components/portfolio";

import ContactHeader from "../components/contactText";
import ContactContent from "../components/contact";

import Empty from "../components/empty";

import ScrollTop from "../components/ScrollTop";

import background1 from "../img/Picture1.png";

//Animations
import { motion } from "framer-motion";

const Home = () => {
  return (
    <motion.div className=" bg-fixed min-h-full max-h-full max-w-screen" 
    style={{ backgroundImage: `url(${background1})`}}
  >
      <Nav />

      <Banner />
    
      <SkillsHeader/>
      <SkillsContent />

      <PortfolioHeader />
      <PortfolioContent />

      <AboutMe />
      <About />

      <ContactHeader />
      <ContactContent />
      <Empty/>

      <Footer />
      
      <ScrollTop />

    </motion.div>
  );
};

export default Home;