import React from "react";
//Page Components
import Nav from "../../components/nav";
import Footer from "../../components/footer";

import background1 from "../../img/Picture1.png";

import ScrollTop from "../../components/ScrollTop";
//Animations
import { motion } from "framer-motion";

import ModalImage from "react-modal-image-react-17";


//gallery
import image1 from "../../img/AM/1.png"
import image2 from "../../img/AM/2.png"
import image3 from "../../img/AM/3.png"
import image4 from "../../img/AM/4.png"
import image5 from "../../img/AM/5.png"




const images = [
    { source: image1, isOpen: false },
    { source: image2, isOpen: false },
    { source: image3, isOpen: false },
    { source: image4, isOpen: false },
    { source: image5, isOpen: false },
]



const Home = () => {
    return (
        <motion.div className=" bg-fixed min-h-full max-h-full max-w-screen"
            style={{ backgroundImage: `url(${background1})` }}

            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <Nav />

            <div className="relative px-4 py-16 sm:px-6 sm:py-20 lg:py-20 lg:px-8">
                <h1 className="text-center md:text-5xl text-5xl sm:text-5xl font-extrabold font-montserrat lg:text-7xl">
                    <a href="https://www.aminmarkaiclothing.com" className="block text-transparent bg-clip-text bg-gradient-to-r p-[2px] from-[#D7D7D7] to-[#4d6bcb] ">AMIN MARKAI CLOTHING</a>
                </h1>
            </div>

            <section class="overflow-hidden text-gray-700 ">
                <div class="container px-5 py-2 mx-auto lg:pt-12 lg:px-32">
                    <div class="flex flex-wrap -m-1 md:-m-2">
                        {images.map((item) => (
                            <div class="flex flex-wrap w-1/2">
                                <div class="w-full p-1 md:p-2">

                                    <ModalImage
                                        className="block object-cover object-center w-full h-full rounded-lg"
                                        small={item.source}
                                        large={item.source}
                                        hideDownload
                                    />;

                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </section>


            <Footer />
            <ScrollTop />


        </motion.div>
    );
};

export default Home;