import React from "react";
//Page Components
import Nav from "../components/nav";
import Footer from "../components/footer";

import SkillsHeader from "../components/skillText";
import SkillsContent from "../components/skill";

import Empty from "../components/empty";

import background1 from "../img/Picture1.png";

import ScrollTop from "../components/ScrollTop";

//Animations
import { motion } from "framer-motion";

const Home = () => {
  return (
    <motion.div className=" bg-fixed min-h-full max-h-full max-w-screen" style={{
      backgroundImage: `url(${background1})`
    }}>

      <Nav />

      <SkillsHeader />
      <SkillsContent />

      <Empty />

      <Footer />
      <ScrollTop />

    </motion.div>
  );
};

export default Home;