import React from "react";

//Import Pages
import Home from "./pages/home";
import About from "./pages/about";
import Skill from "./pages/skill";
import Work from "./pages/work";
import Contact from "./pages/contact";

// Gallery
import GLOW from "./pages/gallery/GLOW";
import Ovetra from "./pages/gallery/Ovetra";
import MJ from "./pages/gallery/MJ";
import NO from "./pages/gallery/NO";
import RC from "./pages/gallery/RC";
import PMK from "./pages/gallery/PMK";
import AM from "./pages/gallery/AM";



//Router
import { Routes, Route, useLocation } from "react-router-dom";
//Animation
import { AnimatePresence } from "framer-motion";


import "./style/scroll.css";


function App() {

  const location = useLocation();
  return (
    <div className="App bg-background min-h-screen max-w-screen scroll-smooth">
      <AnimatePresence exitBeforeEnter>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/skill" element={<Skill />} />
          <Route path="/work" element={<Work />} />
          <Route path="/contact" element={<Contact />} />

          //gallery
          <Route path="/work/GLOW" element={<GLOW />} />
          <Route path="/work/ovetra" element={<Ovetra />} />
          <Route path="/work/major-capital" element={<MJ />} />
          <Route path="/work/rudie-crossman" element={<RC />} />
          <Route path="/work/nigel-overman" element={<NO />} />
          <Route path="/work/pmk-interiors" element={<PMK />} />
          <Route path="/work/amin-markai" element={<AM />} />


        </Routes >
      </AnimatePresence>
      
    </div>
  );
}

export default App;
